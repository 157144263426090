.login-img {
  background-size: contain;
  object-fit: contain;
}

.form-control:focus,
.form-control:active {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dbbe78;
}

.checkbox-primary {
  color: #dbbe78;
}
.checkbox-primary input:checked ~ .checkmark:after {
  color: #dbbe78;
}

.btn {
  cursor: pointer;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
}
.btn.btn-primary {
  background: #dbbe78 !important;
  border: 1px solid #dbbe78 !important;
  color: #060708 !important;
}
.btn.btn-primary:hover {
  border: 1px solid #dbbe78;
  background: transparent;
  color: #dbbe78;
}
.btn.btn-primary.btn-outline-primary {
  border: 1px solid #dbbe78;
  background: transparent;
  color: #dbbe78;
}
.btn.btn-primary.btn-outline-primary:hover {
  border: 1px solid transparent;
  background: #dbbe78;
  color: #060708;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #e3b04b;
}
a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bg-primary {
  background: #e3b04b !important;
}

.ftco-section {
  padding: 7em 0;
}

.ftco-no-pt {
  padding-top: 0;
}

.ftco-no-pb {
  padding-bottom: 0;
}

.img,
.login-wrap {
  width: 50%;
}
@media (max-width: 991.98px) {
  .img,
  .login-wrap {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .wrap .img {
    height: 250px;
  }
}

.login-wrap {
  position: relative;
  background: #fff h3;
  background-font-weight: 300;
}

.form-group {
  position: relative;
}
.form-group .label {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
  font-weight: 700;
}
.form-group a {
  color: gray;
}
